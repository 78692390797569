import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../helpers/AxiosHelper';

export const subscriptionSlice = createApi({
  reducerPath: 'subscription',
  baseQuery: axiosBaseQuery({
    baseURL: process.env.REACT_APP_V3_BASE_URL,
  }),
  tagTypes: ['subscription'],
  endpoints: (builder) => ({

    // Create a new subscription
    createSubscription: builder.mutation({
      query: ({ data, organizationId }) => {
        const url = `/subscription/${organizationId}/create`;
        return { url, method: 'POST', data };
      },
      invalidatesTags: ['Subscription'],
    }),
    updateSubscription: builder.mutation({
      query: ({ data, organizationId }) => {
        const url = `/subscription/${organizationId}/update`;
        return { url, method: 'PATCH', data };
      },
      invalidatesTags: ['Subscription'],
    }),
    getSubscription: builder.query({
      query: (organizationId) => {
        const url = `/subscription/${organizationId}/getSubscription/`;
        return { url, method: 'GET' };
      },
      providesTags: ['Subscription'],
    }),
  }),
});

export const {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useGetSubscriptionQuery,
} = subscriptionSlice;

export default subscriptionSlice;
