import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Button,
  Date,
  Select,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { useCreateSubscriptionMutation, useUpdateSubscriptionMutation } from '../services/SubscriptionService';
import NotificationService from '../services/NotificationService';
import useFetchSubscription from '../hooks/useFetchSubscription';
import PageLoader from './PageLoader';

const SubscriptionComponent = () => {
  const defaultSubscription = {
    effectiveDate: null,
    messageLimitCount: '',
    messageLimitType: 1,
    growthCount: '',
    growthPrice: '',
    salesforceLink: '',
    id: 0,
  };

  const options = [
    { id: 1, value: '---' },
    { id: 2, value: 'Monthly' },
    { id: 3, value: 'Yearly' },
  ];

  const [formInputs, setFormInputs] = useState(defaultSubscription);
  const organizationId = useSelector((state) => state.auth.currentOrg);
  const [createSubscription] = useCreateSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const { data, isLoading: isLoadingData } = useFetchSubscription(organizationId);

  useEffect(() => {
    if (data) {
      const subscriptionData = data.subscription;
      setFormInputs({
        effectiveDate: moment(subscriptionData.effectiveDate) || null,
        messageLimitCount: subscriptionData.messageLimitCount || '',
        messageLimitType: subscriptionData.messageLimitType || 1,
        growthCount: subscriptionData.growthCount || '',
        growthPrice: subscriptionData.growthPrice || '',
        salesforceLink: subscriptionData.salesforceLink || '',
        id: subscriptionData.id || 0,
      });
    }
  }, [data]);

  if (isLoadingData) {
    return <PageLoader />;
  }

  function convertNumberToCurrency(inputValue) {
    if (!inputValue) return '';

    // Remove all commas and ensure it's a valid number
    const number = parseFloat(inputValue.replace(/,/g, ''));

    // If the number is not valid, return an empty string
    if (Number.isNaN(number)) return '';

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }
  const handleChange = (fieldName, value) => {
    if (fieldName === 'growthPrice') {
      const currency = convertNumberToCurrency(value);
      setFormInputs((current) => ({ ...current, [fieldName]: currency }));
    } else {
      setFormInputs((current) => ({ ...current, [fieldName]: value }));
    }
  };

  const handleSelect = (fieldName, value) => {
    setFormInputs((current) => ({ ...current, [fieldName]: value }));
  };

  const handleDateChange = (date) => {
    setFormInputs((current) => ({ ...current, effectiveDate: date }));
  };

  const handleCancel = () => {
    setFormInputs(defaultSubscription);
  };

  const handleSave = async () => {
    const newSubscriptionData = {
      id: formInputs.id,
      effectiveDate: formInputs.effectiveDate ? moment(formInputs.effectiveDate).format('YYYY-MM-DD') : '1900-00-00',
      messageLimitCount: formInputs.messageLimitCount ? Number(formInputs.messageLimitCount) : 0,
      messageLimitType: formInputs.messageLimitType ? Number(formInputs.messageLimitType) : 1,
      growthCount: formInputs.growthCount ? Number(formInputs.growthCount) : 0,
      growthPrice: formInputs.growthPrice ? Number(formInputs.growthPrice.replace(/,/g, '')) : 0,
      salesforceLink: formInputs.salesforceLink || '',
    };
    const notificationMessage = formInputs.id ? 'updateSubscription' : 'createSubscription';
    const response = formInputs.id ?
      await updateSubscription({ data: newSubscriptionData, organizationId }) :
      await createSubscription({ data: newSubscriptionData, organizationId });

    if (response?.data) {
      NotificationService(notificationMessage, { status: 200 });
    } else {
      NotificationService(notificationMessage);
    }
  };

  return (
    <div>
      <div className="app-page__container">
        <div className="app-page__container__inner">
          <div className="app-page__header">
            <div className="app-page__header__title" data-cypress="orgProfileTitle">
              Subscription
            </div>
          </div>

          <form>
            <div className="box">
              <div className="box__title-wrapper">
                <div className="box__title">CONTRACT INFORMATION</div>
              </div>

              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Date
                      id="effectiveDate"
                      label="Effective Date"
                      name="effectiveDate"
                      explanationMessage="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                      selected={formInputs.effectiveDate}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      label="Message Limit Count"
                      name="messageLimitCount"
                      format={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 0,
                      }}
                      onChange={handleChange}
                      initialValue={formInputs.messageLimitCount}
                    />
                  </div>

                  <div className="column-4@small">
                    <Select
                      name="messageLimitType"
                      label="Message Limit Type"
                      options={options}
                      onSelect={handleSelect}
                      selected={formInputs.messageLimitType}
                    />
                  </div>
                </div>
              </div>

              <div className="form__group">
                <div className="row">
                  <div className="column-4@small">
                    <Input
                      label="Growth Count"
                      format={{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 0,
                      }}
                      name="growthCount"
                      onChange={handleChange}
                      initialValue={formInputs.growthCount}
                    />
                  </div>
                  <div className="column-4@small">
                    <Input
                      label="Growth Price"
                      name="growthPrice"
                      onChange={handleChange}
                      initialValue={formInputs.growthPrice}
                    />
                  </div>
                </div>
              </div>
              <div className="form__group">
                <div className="row">
                  <div className="column-7@small">
                    <Input
                      label="Link to SalesForce Profile"
                      name="salesforceLink"
                      onChange={handleChange}
                      initialValue={formInputs.salesforceLink}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="u-m-t-large u-text-right">
              <Button type="link" onClick={handleCancel}>
                Cancel
              </Button>

              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionComponent;
